import { PageMeta } from 'src/pages'
import Head from 'next/head'
import { Header, HeaderProps } from './patterns/Header'
import { Footer, FooterProps } from './patterns/Footer'

export const GlobalStyles: React.FC = ({ children }) => (
  <div className="text-navy-900 antialiased">{children}</div>
)

export const Layout: React.FC<{
  meta: PageMeta
}> = ({ meta: { title, description }, children }) => {
  const header: HeaderProps = {
    pages: [
      { title: 'Sales', url: '/cinemes' },
      { title: 'FAQs', url: '/faqs' },
      // { title: 'Notícies', url: '/noticies' },
      { title: 'Passaport', url: '/passaport' },
      { title: 'Contacte', url: '/contacte' },
    ],
    dropdown: {
      title: 'Programació',
      baseUrl: '/programacio',
      items: [
        {
          label: 'Pel·lícula del mes',
          value: '/actualment',
          description: 'Aquest mes programem',
        },
        {
          label: 'Properament',
          value: '/properament',
          description: 'Properes pel·lícules a CineXic',
        },
        {
          label: 'Anteriorment',
          value: '/anteriorment',
          description: 'Pel·lícules projectades a CineXic',
        },
      ],
    },
  }

  const footer: FooterProps = {
    promoters: {
      groups: [
        {
          title: 'Projecte impulsat per',
          promoters: [
            {
              name: 'Òmnium Cultural',
              image: '/img/omnium.svg',
              url: 'https://omnium.cat',
            },
          ],
        },
        {
          title: 'Amb la col·laboració de',
          promoters: [
            {
              name: 'Rita & Luca Films',
              image: '/img/rica-luca.svg',
              url: 'https://www.ritalucafilms.com/',
            },
            {
              name: 'Consorci per la normalització lingüística',
              image: '/img/pack-magic.svg',
              url: 'https://www.packmagic.cat/',
            },
          ],
        },
      ],
    },
    links: [
      { title: 'Contacte', url: '/contacte' },
      { title: 'Avís legal', url: '/avis-legal' },
      { title: 'Política de privacitat', url: '/politica-de-privacitat' },
      { title: 'Política de cookies', url: '/politica-de-cookies' },
      { title: "Canal d'informacions", url: 'https://centinela.omnium.cat/' },
      { title: 'Admin', url: '/admin' },
    ],
    instagramUrl: 'https://www.instagram.com/omniumcultural/',
    facebookUrl: 'https://www.facebook.com/omniumcultural/',
    twitterUrl: 'https://twitter.com/omnium',
    telegramUrl: 'https://t.me/omnium',
    youtubeUrl: 'https://www.youtube.com/user/omniumcultural',
    phone: '93 319 80 50',
    email: 'cinexic@omnium.cat',
    address:
      "Seu Nacional d'Òmnium Cultural Diputació, 276, pral. 08009 Barcelona",
    copyright: '© 2021 Cinexic. Tots els drets reservats.',
    signupUrl:
      'https://fes-te-soci.omnium.cat/?utm_source=cinexic.cat&utm_medium=referral&utm_campaign=cinexic',
    signupText: "Fes-te soci d'Òmnium",
  }

  return (
    <GlobalStyles>
      <div className="flex flex-col min-h-full">
        <Head>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Head>

        <Header {...header} />
        <main className="flex-1">{children}</main>
        <Footer {...footer} />
      </div>
    </GlobalStyles>
  )
}
