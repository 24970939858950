import classNames from 'classnames'
import {
  AlertCircleOutline,
  CheckmarkCircleOutline,
  CloseCircleOutline,
} from 'react-ionicons'
import { IonIcon } from '@/src/icons'

export interface AlertProps {
  className?: string
  title: string
  description: string
  type: 'success' | 'warning' | 'error'
}

export const Alert = ({
  className = '',
  title,
  description,
  type,
}: AlertProps) => {
  const isSuccess = type === 'success'
  const isError = type === 'error'
  const isWarning = type === 'warning'
  const iconMap = {
    success: CheckmarkCircleOutline,
    warning: AlertCircleOutline,
    error: CloseCircleOutline,
  }
  return (
    <div
      className={classNames(
        'flex items-start pl-5 pr-10 py-5 bg-white border rounded-md',
        isSuccess && 'border-success',
        isError && 'border-alert',
        isWarning && 'border-attention',
        className
      )}
    >
      <IonIcon
        icon={iconMap[type]}
        className={classNames(
          'flex-shrink-0 w-6 fill-current',
          isSuccess && 'text-success',
          isWarning && 'text-attention',
          isError && 'text-alert'
        )}
        aria-hidden
      />
      <div className="ml-4">
        <h5 className="text-navy-900 font-semibold leading-6">{title}</h5>
        <p className="mt-4 text-navy-light-700 break-normal text-sm">
          {description}
        </p>
      </div>
    </div>
  )
}
