import type { Timestamp } from '@google-cloud/firestore'

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Timestamp: Timestamp;
};

export type Booking = {
  __typename?: 'Booking';
  created: Scalars['Timestamp'];
  email: Scalars['String'];
  emailValidated: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  numberOfTickets: Scalars['Int'];
  phone: Scalars['String'];
  projectionId: Scalars['String'];
  surname: Scalars['String'];
  token: Scalars['String'];
  unsubscribeToken: Scalars['String'];
  updated: Scalars['Timestamp'];
};

export type BookingResponse = Booking | SimpleBooking;

export type Cinema = {
  __typename?: 'Cinema';
  address: Scalars['String'];
  addresslink?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  coordinates: Coordinates;
  cover?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  movies?: Maybe<Array<Maybe<Movie>>>;
  name: Scalars['String'];
  province: Scalars['String'];
  token?: Maybe<Scalars['String']>;
};

export type CinemaSignUpMessage = {
  __typename?: 'CinemaSignUpMessage';
  cinema: Scalars['String'];
  email: Scalars['String'];
  location: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  province: Scalars['String'];
  surname: Scalars['String'];
};

export type ContactMessage = {
  __typename?: 'ContactMessage';
  email: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
};

export type Coordinates = {
  __typename?: 'Coordinates';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type Image = {
  __typename?: 'Image';
  image: Scalars['String'];
};

export type Movie = {
  __typename?: 'Movie';
  authorship: Scalars['String'];
  awards?: Maybe<Array<Maybe<Scalars['String']>>>;
  bookings?: Maybe<Array<Maybe<BookingResponse>>>;
  cover: Scalars['String'];
  description: Scalars['String'];
  duration: Scalars['String'];
  id: Scalars['ID'];
  language: Scalars['String'];
  minimumAge?: Maybe<Scalars['String']>;
  originalTitle: Scalars['String'];
  presskit: Scalars['String'];
  projections?: Maybe<Array<Maybe<Projection>>>;
  slides?: Maybe<Array<Maybe<Image>>>;
  title: Scalars['String'];
  topics?: Maybe<Array<Maybe<Scalars['String']>>>;
  videoUrl: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createBooking: Booking;
  invalidateBooking?: Maybe<Booking>;
  sendCinemaSignUpMessage: CinemaSignUpMessage;
  sendContactMessage?: Maybe<ContactMessage>;
  subscribeToNewsletter?: Maybe<NewsletterSubscription>;
  validateBooking?: Maybe<Booking>;
};


export type MutationCreateBookingArgs = {
  data?: Maybe<CreateBookingInput>;
};


export type MutationInvalidateBookingArgs = {
  id: Scalars['ID'];
};


export type MutationSendCinemaSignUpMessageArgs = {
  data?: Maybe<SendCinemaSignUpMessageInput>;
};


export type MutationSendContactMessageArgs = {
  data?: Maybe<SendContactMessageInput>;
};


export type MutationSubscribeToNewsletterArgs = {
  email: Scalars['String'];
};


export type MutationValidateBookingArgs = {
  id: Scalars['ID'];
};

export type NewsletterSubscription = {
  __typename?: 'NewsletterSubscription';
  created: Scalars['Timestamp'];
  email: Scalars['String'];
  id: Scalars['ID'];
  updated: Scalars['Timestamp'];
};

export type Projection = {
  __typename?: 'Projection';
  bookings?: Maybe<Array<Maybe<BookingResponse>>>;
  cinema: Scalars['String'];
  date: Scalars['String'];
  id: Scalars['ID'];
  numberOfAvailableSeats?: Maybe<Scalars['Int']>;
  numberOfSeats: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  booking?: Maybe<Booking>;
  cinema?: Maybe<Cinema>;
  cinemas?: Maybe<Array<Maybe<Cinema>>>;
  movie?: Maybe<Movie>;
  projection?: Maybe<Projection>;
  subscriptions?: Maybe<Array<Maybe<NewsletterSubscription>>>;
  test?: Maybe<Scalars['Boolean']>;
};


export type QueryBookingArgs = {
  bookingId?: Maybe<Scalars['ID']>;
};


export type QueryCinemaArgs = {
  cinemaId: Scalars['ID'];
};


export type QueryMovieArgs = {
  movieId: Scalars['ID'];
};


export type QueryProjectionArgs = {
  projectionId: Scalars['ID'];
};

export type SimpleBooking = {
  __typename?: 'SimpleBooking';
  created: Scalars['Timestamp'];
  emailValidated: Scalars['Boolean'];
  name: Scalars['String'];
  numberOfTickets: Scalars['Int'];
  projectionId: Scalars['String'];
  surname: Scalars['String'];
};

export type BookingsFilterInput = {
  projectionId?: Maybe<Scalars['String']>;
};

export type CreateBookingInput = {
  email: Scalars['String'];
  emailValidated: Scalars['Boolean'];
  name: Scalars['String'];
  numberOfTickets: Scalars['Int'];
  phone: Scalars['String'];
  projectionId: Scalars['String'];
  surname: Scalars['String'];
};

export type SendCinemaSignUpMessageInput = {
  cinema: Scalars['String'];
  email: Scalars['String'];
  location: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  province: Scalars['String'];
  surname: Scalars['String'];
};

export type SendContactMessageInput = {
  email: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
};

export type CreateBookingMutationMutationVariables = Exact<{
  createBookingData?: Maybe<CreateBookingInput>;
}>;


export type CreateBookingMutationMutation = { __typename?: 'Mutation', createBooking: { __typename?: 'Booking', id: string, projectionId: string, name: string, surname: string, phone: string, email: string, numberOfTickets: number, emailValidated: boolean } };

export type InvalidateBookingMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type InvalidateBookingMutation = { __typename?: 'Mutation', invalidateBooking?: { __typename?: 'Booking', id: string, email: string, emailValidated: boolean, name: string, surname: string, phone: string, numberOfTickets: number, projectionId: string, created: Timestamp, updated: Timestamp } | null };

export type SendCinemaSignUpMessageMutationMutationVariables = Exact<{
  sendCinemaSignUpMessageData?: Maybe<SendCinemaSignUpMessageInput>;
}>;


export type SendCinemaSignUpMessageMutationMutation = { __typename?: 'Mutation', sendCinemaSignUpMessage: { __typename?: 'CinemaSignUpMessage', name: string, surname: string, cinema: string, email: string, phone?: string | null, province: string, location: string } };

export type SendContactMessageMutationMutationVariables = Exact<{
  sendContactMessageData?: Maybe<SendContactMessageInput>;
}>;


export type SendContactMessageMutationMutation = { __typename?: 'Mutation', sendContactMessage?: { __typename?: 'ContactMessage', name: string, surname: string, email: string, phone?: string | null, message: string } | null };

export type SubscribeToNewsletterMutationMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SubscribeToNewsletterMutationMutation = { __typename?: 'Mutation', subscribeToNewsletter?: { __typename?: 'NewsletterSubscription', id: string, email: string, created: Timestamp, updated: Timestamp } | null };

export type ValidateBookingMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ValidateBookingMutation = { __typename?: 'Mutation', validateBooking?: { __typename?: 'Booking', id: string, email: string, emailValidated: boolean, name: string, surname: string, phone: string, numberOfTickets: number, projectionId: string, created: Timestamp, updated: Timestamp } | null };

export type BookingFragment = { __typename?: 'Booking', id: string, email: string, emailValidated: boolean, name: string, surname: string, phone: string, numberOfTickets: number, projectionId: string, created: Timestamp, updated: Timestamp };

export type SimpleBookingFragment = { __typename?: 'SimpleBooking', created: Timestamp, name: string, surname: string, numberOfTickets: number, emailValidated: boolean, projectionId: string };

type BookingResponse_Booking_Fragment = { __typename?: 'Booking', id: string, token: string, unsubscribeToken: string, name: string, projectionId: string, surname: string, phone: string, email: string, emailValidated: boolean, numberOfTickets: number, created: Timestamp, updated: Timestamp };

type BookingResponse_SimpleBooking_Fragment = { __typename?: 'SimpleBooking', created: Timestamp, name: string, surname: string, numberOfTickets: number, emailValidated: boolean, projectionId: string };

export type BookingResponseFragment = BookingResponse_Booking_Fragment | BookingResponse_SimpleBooking_Fragment;

export type GetProjectionsForMovieQueryVariables = Exact<{
  movieId: Scalars['ID'];
}>;


export type GetProjectionsForMovieQuery = { __typename?: 'Query', movie?: { __typename?: 'Movie', projections?: Array<{ __typename?: 'Projection', id: string, date: string, numberOfSeats: number, numberOfAvailableSeats?: number | null } | null> | null } | null };

export type MovieFragment = { __typename?: 'Movie', id: string, title: string, originalTitle: string, description: string, cover: string, authorship: string, videoUrl: string, topics?: Array<string | null> | null, awards?: Array<string | null> | null, minimumAge?: string | null, duration: string, language: string, presskit: string, slides?: Array<{ __typename?: 'Image', image: string } | null> | null, projections?: Array<{ __typename?: 'Projection', id: string, cinema: string, date: string, numberOfSeats: number, numberOfAvailableSeats?: number | null, bookings?: Array<{ __typename?: 'Booking', id: string, email: string, emailValidated: boolean, name: string, surname: string, phone: string, numberOfTickets: number, projectionId: string, created: Timestamp, updated: Timestamp } | { __typename?: 'SimpleBooking', created: Timestamp, name: string, surname: string, numberOfTickets: number, emailValidated: boolean, projectionId: string } | null> | null } | null> | null, bookings?: Array<{ __typename?: 'Booking', id: string, email: string, emailValidated: boolean, name: string, surname: string, phone: string, numberOfTickets: number, projectionId: string, created: Timestamp, updated: Timestamp } | { __typename?: 'SimpleBooking' } | null> | null };

export type ProjectionFragment = { __typename?: 'Projection', id: string, cinema: string, date: string, numberOfSeats: number, numberOfAvailableSeats?: number | null, bookings?: Array<{ __typename?: 'Booking', id: string, email: string, emailValidated: boolean, name: string, surname: string, phone: string, numberOfTickets: number, projectionId: string, created: Timestamp, updated: Timestamp } | { __typename?: 'SimpleBooking', created: Timestamp, name: string, surname: string, numberOfTickets: number, emailValidated: boolean, projectionId: string } | null> | null };

export type GetAllBookingsQueryVariables = Exact<{
  cinemaId: Scalars['ID'];
}>;


export type GetAllBookingsQuery = { __typename?: 'Query', cinema?: { __typename?: 'Cinema', id: string, name: string, movies?: Array<{ __typename?: 'Movie', id: string, title: string, projections?: Array<{ __typename?: 'Projection', date: string, bookings?: Array<{ __typename?: 'Booking', id: string, email: string, emailValidated: boolean, name: string, surname: string, phone: string, numberOfTickets: number, projectionId: string, created: Timestamp, updated: Timestamp } | { __typename?: 'SimpleBooking', created: Timestamp, name: string, surname: string, numberOfTickets: number, emailValidated: boolean, projectionId: string } | null> | null } | null> | null } | null> | null } | null };

export type AllCinemasQueryVariables = Exact<{ [key: string]: never; }>;


export type AllCinemasQuery = { __typename?: 'Query', cinemas?: Array<{ __typename?: 'Cinema', id: string, name: string, city: string, province: string, token?: string | null } | null> | null };

export type GetMovieQueryVariables = Exact<{
  movieId: Scalars['ID'];
}>;


export type GetMovieQuery = { __typename?: 'Query', movie?: { __typename?: 'Movie', id: string, title: string, originalTitle: string, description: string, cover: string, authorship: string, videoUrl: string, topics?: Array<string | null> | null, awards?: Array<string | null> | null, minimumAge?: string | null, duration: string, language: string, presskit: string, slides?: Array<{ __typename?: 'Image', image: string } | null> | null, projections?: Array<{ __typename?: 'Projection', id: string, cinema: string, date: string, numberOfSeats: number, numberOfAvailableSeats?: number | null, bookings?: Array<{ __typename?: 'Booking', id: string, email: string, emailValidated: boolean, name: string, surname: string, phone: string, numberOfTickets: number, projectionId: string, created: Timestamp, updated: Timestamp } | { __typename?: 'SimpleBooking', created: Timestamp, name: string, surname: string, numberOfTickets: number, emailValidated: boolean, projectionId: string } | null> | null } | null> | null, bookings?: Array<{ __typename?: 'Booking', id: string, email: string, emailValidated: boolean, name: string, surname: string, phone: string, numberOfTickets: number, projectionId: string, created: Timestamp, updated: Timestamp } | { __typename?: 'SimpleBooking' } | null> | null } | null };

export type GetProjectionQueryVariables = Exact<{
  projectionId: Scalars['ID'];
}>;


export type GetProjectionQuery = { __typename?: 'Query', projection?: { __typename?: 'Projection', id: string, cinema: string, date: string, numberOfSeats: number, numberOfAvailableSeats?: number | null, bookings?: Array<{ __typename?: 'Booking', id: string, email: string, emailValidated: boolean, name: string, surname: string, phone: string, numberOfTickets: number, projectionId: string, created: Timestamp, updated: Timestamp } | { __typename?: 'SimpleBooking', created: Timestamp, name: string, surname: string, numberOfTickets: number, emailValidated: boolean, projectionId: string } | null> | null } | null };

export const BookingResponseFragmentDoc = gql`
    fragment BookingResponse on BookingResponse {
  ... on Booking {
    id
    token
    unsubscribeToken
    name
    projectionId
    surname
    phone
    email
    emailValidated
    numberOfTickets
    created
    updated
  }
  ... on SimpleBooking {
    created
    name
    surname
    numberOfTickets
    emailValidated
    projectionId
  }
}
    `;
export const BookingFragmentDoc = gql`
    fragment Booking on Booking {
  id
  email
  emailValidated
  name
  surname
  phone
  numberOfTickets
  projectionId
  created
  updated
}
    `;
export const SimpleBookingFragmentDoc = gql`
    fragment SimpleBooking on SimpleBooking {
  created
  name
  surname
  numberOfTickets
  emailValidated
  projectionId
}
    `;
export const ProjectionFragmentDoc = gql`
    fragment Projection on Projection {
  id
  cinema
  date
  numberOfSeats
  numberOfAvailableSeats
  bookings {
    ... on Booking {
      ...Booking
    }
    ... on SimpleBooking {
      ...SimpleBooking
    }
  }
}
    ${BookingFragmentDoc}
${SimpleBookingFragmentDoc}`;
export const MovieFragmentDoc = gql`
    fragment Movie on Movie {
  id
  title
  originalTitle
  description
  cover
  slides {
    image
  }
  projections {
    ...Projection
  }
  authorship
  videoUrl
  topics
  awards
  minimumAge
  duration
  language
  presskit
  bookings {
    ...Booking
  }
}
    ${ProjectionFragmentDoc}
${BookingFragmentDoc}`;
export const CreateBookingMutationDocument = gql`
    mutation CreateBookingMutation($createBookingData: createBookingInput) {
  createBooking(data: $createBookingData) {
    id
    projectionId
    name
    surname
    phone
    email
    numberOfTickets
    emailValidated
  }
}
    `;
export type CreateBookingMutationMutationFn = Apollo.MutationFunction<CreateBookingMutationMutation, CreateBookingMutationMutationVariables>;

/**
 * __useCreateBookingMutationMutation__
 *
 * To run a mutation, you first call `useCreateBookingMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingMutationMutation, { data, loading, error }] = useCreateBookingMutationMutation({
 *   variables: {
 *      createBookingData: // value for 'createBookingData'
 *   },
 * });
 */
export function useCreateBookingMutationMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookingMutationMutation, CreateBookingMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookingMutationMutation, CreateBookingMutationMutationVariables>(CreateBookingMutationDocument, options);
      }
export type CreateBookingMutationMutationHookResult = ReturnType<typeof useCreateBookingMutationMutation>;
export type CreateBookingMutationMutationResult = Apollo.MutationResult<CreateBookingMutationMutation>;
export type CreateBookingMutationMutationOptions = Apollo.BaseMutationOptions<CreateBookingMutationMutation, CreateBookingMutationMutationVariables>;
export const InvalidateBookingDocument = gql`
    mutation InvalidateBooking($id: ID!) {
  invalidateBooking(id: $id) {
    ...Booking
  }
}
    ${BookingFragmentDoc}`;
export type InvalidateBookingMutationFn = Apollo.MutationFunction<InvalidateBookingMutation, InvalidateBookingMutationVariables>;

/**
 * __useInvalidateBookingMutation__
 *
 * To run a mutation, you first call `useInvalidateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvalidateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invalidateBookingMutation, { data, loading, error }] = useInvalidateBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvalidateBookingMutation(baseOptions?: Apollo.MutationHookOptions<InvalidateBookingMutation, InvalidateBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvalidateBookingMutation, InvalidateBookingMutationVariables>(InvalidateBookingDocument, options);
      }
export type InvalidateBookingMutationHookResult = ReturnType<typeof useInvalidateBookingMutation>;
export type InvalidateBookingMutationResult = Apollo.MutationResult<InvalidateBookingMutation>;
export type InvalidateBookingMutationOptions = Apollo.BaseMutationOptions<InvalidateBookingMutation, InvalidateBookingMutationVariables>;
export const SendCinemaSignUpMessageMutationDocument = gql`
    mutation SendCinemaSignUpMessageMutation($sendCinemaSignUpMessageData: sendCinemaSignUpMessageInput) {
  sendCinemaSignUpMessage(data: $sendCinemaSignUpMessageData) {
    name
    surname
    cinema
    email
    phone
    province
    location
  }
}
    `;
export type SendCinemaSignUpMessageMutationMutationFn = Apollo.MutationFunction<SendCinemaSignUpMessageMutationMutation, SendCinemaSignUpMessageMutationMutationVariables>;

/**
 * __useSendCinemaSignUpMessageMutationMutation__
 *
 * To run a mutation, you first call `useSendCinemaSignUpMessageMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCinemaSignUpMessageMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCinemaSignUpMessageMutationMutation, { data, loading, error }] = useSendCinemaSignUpMessageMutationMutation({
 *   variables: {
 *      sendCinemaSignUpMessageData: // value for 'sendCinemaSignUpMessageData'
 *   },
 * });
 */
export function useSendCinemaSignUpMessageMutationMutation(baseOptions?: Apollo.MutationHookOptions<SendCinemaSignUpMessageMutationMutation, SendCinemaSignUpMessageMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCinemaSignUpMessageMutationMutation, SendCinemaSignUpMessageMutationMutationVariables>(SendCinemaSignUpMessageMutationDocument, options);
      }
export type SendCinemaSignUpMessageMutationMutationHookResult = ReturnType<typeof useSendCinemaSignUpMessageMutationMutation>;
export type SendCinemaSignUpMessageMutationMutationResult = Apollo.MutationResult<SendCinemaSignUpMessageMutationMutation>;
export type SendCinemaSignUpMessageMutationMutationOptions = Apollo.BaseMutationOptions<SendCinemaSignUpMessageMutationMutation, SendCinemaSignUpMessageMutationMutationVariables>;
export const SendContactMessageMutationDocument = gql`
    mutation SendContactMessageMutation($sendContactMessageData: sendContactMessageInput) {
  sendContactMessage(data: $sendContactMessageData) {
    name
    surname
    email
    phone
    message
  }
}
    `;
export type SendContactMessageMutationMutationFn = Apollo.MutationFunction<SendContactMessageMutationMutation, SendContactMessageMutationMutationVariables>;

/**
 * __useSendContactMessageMutationMutation__
 *
 * To run a mutation, you first call `useSendContactMessageMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContactMessageMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContactMessageMutationMutation, { data, loading, error }] = useSendContactMessageMutationMutation({
 *   variables: {
 *      sendContactMessageData: // value for 'sendContactMessageData'
 *   },
 * });
 */
export function useSendContactMessageMutationMutation(baseOptions?: Apollo.MutationHookOptions<SendContactMessageMutationMutation, SendContactMessageMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendContactMessageMutationMutation, SendContactMessageMutationMutationVariables>(SendContactMessageMutationDocument, options);
      }
export type SendContactMessageMutationMutationHookResult = ReturnType<typeof useSendContactMessageMutationMutation>;
export type SendContactMessageMutationMutationResult = Apollo.MutationResult<SendContactMessageMutationMutation>;
export type SendContactMessageMutationMutationOptions = Apollo.BaseMutationOptions<SendContactMessageMutationMutation, SendContactMessageMutationMutationVariables>;
export const SubscribeToNewsletterMutationDocument = gql`
    mutation SubscribeToNewsletterMutation($email: String!) {
  subscribeToNewsletter(email: $email) {
    id
    email
    created
    updated
  }
}
    `;
export type SubscribeToNewsletterMutationMutationFn = Apollo.MutationFunction<SubscribeToNewsletterMutationMutation, SubscribeToNewsletterMutationMutationVariables>;

/**
 * __useSubscribeToNewsletterMutationMutation__
 *
 * To run a mutation, you first call `useSubscribeToNewsletterMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToNewsletterMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToNewsletterMutationMutation, { data, loading, error }] = useSubscribeToNewsletterMutationMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSubscribeToNewsletterMutationMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeToNewsletterMutationMutation, SubscribeToNewsletterMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeToNewsletterMutationMutation, SubscribeToNewsletterMutationMutationVariables>(SubscribeToNewsletterMutationDocument, options);
      }
export type SubscribeToNewsletterMutationMutationHookResult = ReturnType<typeof useSubscribeToNewsletterMutationMutation>;
export type SubscribeToNewsletterMutationMutationResult = Apollo.MutationResult<SubscribeToNewsletterMutationMutation>;
export type SubscribeToNewsletterMutationMutationOptions = Apollo.BaseMutationOptions<SubscribeToNewsletterMutationMutation, SubscribeToNewsletterMutationMutationVariables>;
export const ValidateBookingDocument = gql`
    mutation ValidateBooking($id: ID!) {
  validateBooking(id: $id) {
    ...Booking
  }
}
    ${BookingFragmentDoc}`;
export type ValidateBookingMutationFn = Apollo.MutationFunction<ValidateBookingMutation, ValidateBookingMutationVariables>;

/**
 * __useValidateBookingMutation__
 *
 * To run a mutation, you first call `useValidateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateBookingMutation, { data, loading, error }] = useValidateBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useValidateBookingMutation(baseOptions?: Apollo.MutationHookOptions<ValidateBookingMutation, ValidateBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateBookingMutation, ValidateBookingMutationVariables>(ValidateBookingDocument, options);
      }
export type ValidateBookingMutationHookResult = ReturnType<typeof useValidateBookingMutation>;
export type ValidateBookingMutationResult = Apollo.MutationResult<ValidateBookingMutation>;
export type ValidateBookingMutationOptions = Apollo.BaseMutationOptions<ValidateBookingMutation, ValidateBookingMutationVariables>;
export const GetProjectionsForMovieDocument = gql`
    query GetProjectionsForMovie($movieId: ID!) {
  movie(movieId: $movieId) {
    projections {
      id
      date
      numberOfSeats
      numberOfAvailableSeats
    }
  }
}
    `;

/**
 * __useGetProjectionsForMovieQuery__
 *
 * To run a query within a React component, call `useGetProjectionsForMovieQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectionsForMovieQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectionsForMovieQuery({
 *   variables: {
 *      movieId: // value for 'movieId'
 *   },
 * });
 */
export function useGetProjectionsForMovieQuery(baseOptions: Apollo.QueryHookOptions<GetProjectionsForMovieQuery, GetProjectionsForMovieQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectionsForMovieQuery, GetProjectionsForMovieQueryVariables>(GetProjectionsForMovieDocument, options);
      }
export function useGetProjectionsForMovieLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectionsForMovieQuery, GetProjectionsForMovieQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectionsForMovieQuery, GetProjectionsForMovieQueryVariables>(GetProjectionsForMovieDocument, options);
        }
export type GetProjectionsForMovieQueryHookResult = ReturnType<typeof useGetProjectionsForMovieQuery>;
export type GetProjectionsForMovieLazyQueryHookResult = ReturnType<typeof useGetProjectionsForMovieLazyQuery>;
export type GetProjectionsForMovieQueryResult = Apollo.QueryResult<GetProjectionsForMovieQuery, GetProjectionsForMovieQueryVariables>;
export const GetAllBookingsDocument = gql`
    query GetAllBookings($cinemaId: ID!) {
  cinema(cinemaId: $cinemaId) {
    id
    name
    movies {
      id
      title
      projections {
        date
        bookings {
          ... on Booking {
            ...Booking
          }
          ... on SimpleBooking {
            ...SimpleBooking
          }
        }
      }
    }
  }
}
    ${BookingFragmentDoc}
${SimpleBookingFragmentDoc}`;

/**
 * __useGetAllBookingsQuery__
 *
 * To run a query within a React component, call `useGetAllBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBookingsQuery({
 *   variables: {
 *      cinemaId: // value for 'cinemaId'
 *   },
 * });
 */
export function useGetAllBookingsQuery(baseOptions: Apollo.QueryHookOptions<GetAllBookingsQuery, GetAllBookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBookingsQuery, GetAllBookingsQueryVariables>(GetAllBookingsDocument, options);
      }
export function useGetAllBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBookingsQuery, GetAllBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBookingsQuery, GetAllBookingsQueryVariables>(GetAllBookingsDocument, options);
        }
export type GetAllBookingsQueryHookResult = ReturnType<typeof useGetAllBookingsQuery>;
export type GetAllBookingsLazyQueryHookResult = ReturnType<typeof useGetAllBookingsLazyQuery>;
export type GetAllBookingsQueryResult = Apollo.QueryResult<GetAllBookingsQuery, GetAllBookingsQueryVariables>;
export const AllCinemasDocument = gql`
    query allCinemas {
  cinemas {
    id
    name
    city
    province
    token
  }
}
    `;

/**
 * __useAllCinemasQuery__
 *
 * To run a query within a React component, call `useAllCinemasQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCinemasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCinemasQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCinemasQuery(baseOptions?: Apollo.QueryHookOptions<AllCinemasQuery, AllCinemasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCinemasQuery, AllCinemasQueryVariables>(AllCinemasDocument, options);
      }
export function useAllCinemasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCinemasQuery, AllCinemasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCinemasQuery, AllCinemasQueryVariables>(AllCinemasDocument, options);
        }
export type AllCinemasQueryHookResult = ReturnType<typeof useAllCinemasQuery>;
export type AllCinemasLazyQueryHookResult = ReturnType<typeof useAllCinemasLazyQuery>;
export type AllCinemasQueryResult = Apollo.QueryResult<AllCinemasQuery, AllCinemasQueryVariables>;
export const GetMovieDocument = gql`
    query GetMovie($movieId: ID!) {
  movie(movieId: $movieId) {
    ...Movie
  }
}
    ${MovieFragmentDoc}`;

/**
 * __useGetMovieQuery__
 *
 * To run a query within a React component, call `useGetMovieQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMovieQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMovieQuery({
 *   variables: {
 *      movieId: // value for 'movieId'
 *   },
 * });
 */
export function useGetMovieQuery(baseOptions: Apollo.QueryHookOptions<GetMovieQuery, GetMovieQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMovieQuery, GetMovieQueryVariables>(GetMovieDocument, options);
      }
export function useGetMovieLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMovieQuery, GetMovieQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMovieQuery, GetMovieQueryVariables>(GetMovieDocument, options);
        }
export type GetMovieQueryHookResult = ReturnType<typeof useGetMovieQuery>;
export type GetMovieLazyQueryHookResult = ReturnType<typeof useGetMovieLazyQuery>;
export type GetMovieQueryResult = Apollo.QueryResult<GetMovieQuery, GetMovieQueryVariables>;
export const GetProjectionDocument = gql`
    query GetProjection($projectionId: ID!) {
  projection(projectionId: $projectionId) {
    ...Projection
  }
}
    ${ProjectionFragmentDoc}`;

/**
 * __useGetProjectionQuery__
 *
 * To run a query within a React component, call `useGetProjectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectionQuery({
 *   variables: {
 *      projectionId: // value for 'projectionId'
 *   },
 * });
 */
export function useGetProjectionQuery(baseOptions: Apollo.QueryHookOptions<GetProjectionQuery, GetProjectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectionQuery, GetProjectionQueryVariables>(GetProjectionDocument, options);
      }
export function useGetProjectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectionQuery, GetProjectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectionQuery, GetProjectionQueryVariables>(GetProjectionDocument, options);
        }
export type GetProjectionQueryHookResult = ReturnType<typeof useGetProjectionQuery>;
export type GetProjectionLazyQueryHookResult = ReturnType<typeof useGetProjectionLazyQuery>;
export type GetProjectionQueryResult = Apollo.QueryResult<GetProjectionQuery, GetProjectionQueryVariables>;