import classNames from 'classnames'

export interface CheckboxProps {
  id: string
  type?: string
  label: string
  description?: string | JSX.Element
  name: string
  className?: string
  register: any
  validationRules?: Record<string, any>
  errors?: any
  [key: string]: any
}

export const Checkbox: React.FC<CheckboxProps> = ({
  type = 'checkbox',
  label,
  description = '',
  id,
  name,
  register,
  validationRules,
  errors,
  className = '',
  ...rest
}) => {
  return (
    <div
      className={classNames(
        'relative flex',
        !description ? 'justify-start items-center' : 'items-start',
        className
      )}
    >
      <div className="flex items-center h-6">
        <input
          id={id}
          name={name}
          {...register(name, validationRules)}
          aria-describedby={`description${id}`}
          type={type}
          className={classNames(
            'focus:focused w-4 h-4 text-orange border-gray',
            type === 'checkbox' && 'rounded',
            type === 'radio' && 'rounded-full',
            errors && errors[name] && 'border-attention'
          )}
          {...rest}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={id} className="text-base font-semibold">
          {label}
        </label>
        {description && (
          <div id={`description${id}`} className="text-navy-100">
            {description}
          </div>
        )}
      </div>
    </div>
  )
}
